// extracted by mini-css-extract-plugin
export var insuranceBenefitsSection = "G_gs";
export var insuranceBusinessesSection = "G_gq";
export var insuranceCaseStudySection = "G_gv";
export var insuranceClientQuotesSection = "G_gF";
export var insuranceDevExpertiseSection = "G_gB";
export var insuranceDevServicesIconSection = "G_gp";
export var insuranceOurAchievementsSection = "G_gD";
export var insuranceProcessSection = "G_gr";
export var insuranceProjLogoSection = "G_gz";
export var insuranceRequirementsSection = "G_gw";
export var insuranceSkilledSection = "G_gC";
export var insuranceSuccessStoriesSection = "G_gx";
export var insuranceTechStackSection = "G_gt";
export var insuranceWhyHireSection = "G_gy";